import React, { useState, useEffect, useRef } from 'react';

import S3 from "react-aws-s3";
import { Editor } from '@tinymce/tinymce-react';

import { accessKeyId, secretAccessKey, region, s3Url, bucketName, editorKey } from '../../config';
// @mui material components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup, Select, MenuItem } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import doctorTableData from "layouts/doctor/data/doctorTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllKeywordsAction } from '../../store/KeywordManagement/action';
import { getAllTreatmentsAction } from '../../store/TreatmentManagement/action';
import { getAllServicesAction } from '../../store/ServiceManagement/action';
import { getAllSpecializationsAction } from '../../store/SpecializationManagement/action';
import { getAllActiveLocationsAction } from '../../store/LocationManagement/action';
import { saveDoctorAction, editDoctorAction, getAllDoctorsAction } from '../../store/DoctorManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let keywords = [];
let treatments = [];
let services = [];
let specializations = [];
let locations = [];

function Doctors() {
  const state = useSelector(state=> state.keyword); 
  const treatmentState = useSelector(state=> state.treatment); 
  const serviceState = useSelector(state=> state.service); 
  const specializationState = useSelector(state=> state.specialization); 
  const locationState = useSelector(state=> state.location); 
  const dispatch = useDispatch();
  
  //const [keywords1, setKeywords] = React.useState([]);    
  const [keywordValue, setKeywordValue] = React.useState([]);
  
  //const [treatments1, setTreatments] = React.useState([]); 
  const [treatmentValue, setTreatmentValue] = React.useState([]);

  const [serviceValue, setServiceValue] = React.useState([]);
  const [specializationValue, setSpecializationValue] = React.useState([]);
  
  const [location, setLocation] = useState({});
  const [locationId, setLocationId] = useState(0);

  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  const [s3fileUrl, setS3FileUrl] = React.useState("");
  const [featured, setFeatured] = useState(false);

  //Form Data
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state1, setState] = useState("");
  const [about, setAbout] = useState("");
  const [about1, setAbout1] = useState("");
  const [alt, setAlt] = useState('');
  //const [accrediations, setAccrediations] = useState("");
  const [experience, setExperience] = useState("");
  const [doctorKeywords, setDoctorKeywords] = React.useState([]);
  const [doctorTreatments, setDoctorTreatments] = React.useState([]);
  const [doctorServices, setDoctorServices] = React.useState([]);
  const [doctorSpecializations, setDoctorSpecializations] = React.useState([]);
  const [doctorId, setDoctorId] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFirstName("");
    setLastName("");
    setMobile("");
    setEmail("");
    setCity("");
    setState("");
    setAbout("");
    //setAccrediations("");
    setExperience("");
    setDoctorId("");
    setShowName("");
    setDoctorKeywords([]);
    setDoctorTreatments([]);
    setDoctorServices([]);
    setDoctorSpecializations([]);
    setFeatured(false);
    setShowActive(false);
    setAlt("");
    setOpen(false);
  };

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  }

  const fileInput = useRef();

  useEffect(() => {
    dispatch(getAllKeywordsAction());       
  }, []);

  useEffect(() => {
    dispatch(getAllTreatmentsAction());       
  }, []);

  useEffect(() => {
    dispatch(getAllServicesAction());       
  }, []);

  useEffect(() => {
    dispatch(getAllSpecializationsAction());       
  }, []);

  useEffect(() => {
    dispatch(getAllActiveLocationsAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.keywords !== undefined) {
      keywords = state.keywords;
      //setKeywords(state.keywords);
      //setOptions(state.keywords);
    }
  }, [state]);

  useEffect(() => {
    //Lists
    if (treatmentState.treatments !== undefined) {
      treatments = treatmentState.treatments;
      //setTreatmentOptions(treatmentState.treatments);
    }
  }, [treatmentState]);

  useEffect(() => {
    //Lists
    if (serviceState.services !== undefined) {
      services = serviceState.services;
      //setTreatmentOptions(treatmentState.treatments);
    }
  }, [serviceState]);

  useEffect(() => {
    //Lists
    if (specializationState.specializations !== undefined) {
      specializations = specializationState.specializations;
      //setTreatmentOptions(treatmentState.treatments);
    }
  }, [specializationState]);

  useEffect(() => {
    //Lists
    if (locationState.activeLocations !== undefined) {
      locations = locationState.activeLocations;
      //setTreatmentOptions(treatmentState.treatments);
    }
  }, [locationState]);

  const { columns, rows } = doctorTableData({handleOpen, setFirstName, setLastName, setMobile, setEmail, setLocation, setCity, setState, setAbout, setAbout1, setExperience, setShowName, setShowActive, setDoctorKeywords, setDoctorTreatments, setDoctorServices, setDoctorSpecializations, setDoctorId, setFeatured, setLocationId, setAlt });

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setFileName(getRandomFileName()+e.target.files[0].name);
  }

  const handleFeatureChange = (event) => {
    setFeatured(event.target.value);
  };

  const uploadDoctor = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "doctor",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (doctorId)
    {
      if(fileName != "")
      {
        ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            setS3FileUrl(data.location);
            const formData = {
              "first_name" : firstName,
              "last_name" : lastName,
              "mobile" : mobile,
              "email" : email,
              "city" : city,
              "state" : state1,
              "about" : about,
              // "accrediations" : accrediations,
              "experience" : experience,
              "upload_image" : fileName,
              "keywordValue" : keywordValue,
              "treatmentValue" : treatmentValue,
              "serviceValue" : serviceValue,
              "specializationValue" : specializationValue,
              "featured" : featured,
              "location_id" : locationId,
              'user_alt_text':alt
            }
            dispatch(editDoctorAction(doctorId, formData));
            setDoctorId("");
            dispatch(getAllDoctorsAction()); 
            handleClose();
          })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "first_name" : firstName,
          "last_name" : lastName,
          "mobile" : mobile,
          "email" : email,
          "city" : city,
          "state" : state1,
          "about" : about,
          // "accrediations" : accrediations,
          "experience" : experience,
          "experience" : experience,
          "keywordValue" : keywordValue,
          "treatmentValue" : treatmentValue,
          "serviceValue" : serviceValue,
          "specializationValue" : specializationValue,
          "featured" : featured,
          "location_id" : locationId,
          'user_alt_text':alt
        }
        dispatch(editDoctorAction(doctorId, formData));
        setDoctorId("");
        dispatch(getAllDoctorsAction()); 
        handleClose();
      }
    }else{
      ReactS3Client.uploadFile(file, fileName)
      .then(data => {
        //console.log(data.location);
        setS3FileUrl(data.location);
        const formData = {
          "role_id" : 2,
          "first_name" : firstName,
          "last_name" : lastName,
          "mobile" : mobile,
          "email" : email,
          "city" : city,
          "state" : state1,
          "about" : about,
          // "accrediations" : accrediations,
          "experience" : experience,
          "upload_image" : fileName,
          "keywordValue" : keywordValue,
          "treatmentValue" : treatmentValue,
          "serviceValue" : serviceValue,
          "specializationValue" : specializationValue,
          "featured" : featured,
          'user_alt_text':alt
        }
        dispatch(saveDoctorAction(formData));
        dispatch(getAllDoctorsAction()); 
        handleClose();
      })
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Doctor
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadDoctor}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Featured</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Featured"
                        value={featured}
                        onChange={handleFeatureChange}
                      >
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">First Name</InputLabel>
                      <Input id="title" value={firstName} aria-describedby="my-helper-text" onChange={(e) => setFirstName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Last Name</InputLabel>
                      <Input id="title" value={lastName} aria-describedby="my-helper-text" onChange={(e) => setLastName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Mobile</InputLabel>
                      <Input id="title" value={mobile} aria-describedby="my-helper-text" onChange={(e) => setMobile(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Email Address</InputLabel>
                      <Input id="title" value={email} aria-describedby="my-helper-text" onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        id="size-small-standard-multi"
                        size="small"
                        options={locations}
                        onChange={(event, value) => setLocationId(value.location_id)}
                        getOptionLabel={(option) => option.title}
                        defaultValue={location}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"location"+option.location_id}>
                                {option.title}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Location"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">City</InputLabel>
                      <Input id="description" value={city} aria-describedby="my-helper-text" onChange={(e) => setCity(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">State</InputLabel>
                      <Input id="description" value={state1} aria-describedby="my-helper-text" onChange={(e) => setState(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">About</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setAbout(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={about1}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Qualification</InputLabel>
                      <Input id="description" value={qualification} aria-describedby="my-helper-text" onChange={(e) => setQualification(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Accrediations</InputLabel>
                      <Input id="description" value={accrediations} aria-describedby="my-helper-text" onChange={(e) => setAccrediations(e.target.value)}  />
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Experience</InputLabel>
                      <Input id="description" value={experience} aria-describedby="my-helper-text" onChange={(e) => setExperience(e.target.value)}  />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Alt Text</InputLabel>
                      <Input id="alt tag" aria-describedby="my-helper-text" value={alt} onChange={(e) => setAlt(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/doctor/"+showName} style={{ "width" : 100 }}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="size-small-standard-multi"
                      size="small"
                      options={keywords}
                      onChange={(event, value) => setKeywordValue(value)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={doctorKeywords}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Keyword"
                          variant="standard"
                          //placeholder="Keyword"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={treatments}
                        onChange={(event, value) => setTreatmentValue(value)}
                        getOptionLabel={(option) => option.title}
                        defaultValue={doctorTreatments}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"treatment"+option.treatment_id}>
                                {option.title}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Treatment"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={services}
                        onChange={(event, value) => setServiceValue(value)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={doctorServices}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"service"+option.service_id}>
                                {option.name}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Service"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={specializations}
                        onChange={(event, value) => setSpecializationValue(value)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={doctorSpecializations}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"specialization"+option.specialization_id}>
                                {option.name}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Specialization"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Doctors
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Doctors;
