import { useState, useEffect, useMemo } from "react";
import TechniqueManagement from "layouts/technique";
import FaqManagement from "layouts/faq";
import TreatmentLinkManagement from "layouts/url";
import BodyPartSymptomManagement from "layouts/bodyPartSymptom";
import DiseaseManagement from "layouts/disease";
import DiseaseTreatmentManagement from "layouts/diseaseTreatment";
import CityTreatmentManagement from "layouts/cityTreatment";
import CityCenterManagement from "layouts/CityCenter";
import TestimonialManagement from "layouts/testimonial";
import VideoManagement from "layouts/video";
import LocationBeforeAfterManagement from "layouts/LocationBeforeAfter";
import LocationVideoManagement from "layouts/LocationVideo";
import LocationFaqManagement from "layouts/LocationFaq";
import LocationTestimonialManagement from "layouts/LocationTestimonial";
import TemplateManagement from "layouts/template";
import IvTherapyBenefitManagement from "layouts/ivTherapyBenefit";
import IvTherapyIngredientManagement from "layouts/ivTherapyIngredient";
import IvTherapyTestimonialManagement from "layouts/ivTherapyTestimonial";
import IvTherapyFaqManagement from "layouts/ivTherapyFaq";


//Doctor
import DoctorClinicManagement from "layouts/doctorClinic";
import DoctorEducationManagement from "layouts/doctorEducation";
import DoctorMembershipManagement from "layouts/doctorMembership";
// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import "assets/globals.css";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import { setAuthentication } from './store/Authentication/action';
import { useDispatch } from 'react-redux';
import Verification from "layouts/authentication/otp-verify";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const actionDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const authState = useSelector(state => state.authentication);


  const excludeFromMenuList = ["sign-in"];
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (sessionStorage.getItem('userToken')) {
      actionDispatch(setAuthentication(true));
    }
  },[]);

  const handleLogout = () => {
    //sessionStorage.setItem("userToken", JSON.stringify(result.data.user.data));
    sessionStorage.removeItem('userToken');
    actionDispatch(setAuthentication(false));
  }


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const getMenuRoutes = (allRoutes) => allRoutes.filter(route => !excludeFromMenuList.includes(route.key));

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && authState.isAunthenticated && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Tamira Admin Dashboard"
              routes={getMenuRoutes(routes)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              handleLogout={handleLogout}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        {authState.isAunthenticated || sessionStorage.getItem('userToken') ?
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route path="/react-native" element={ BannerManagement } />
          </Routes> :
          <Routes>
            {getRoutes([routes[0]])}
            <Route path="*" element={<Navigate to="/sign-in" />} />
            <Route path="/verify" exact element={<Verification/>} />
          </Routes>}
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && authState.isAunthenticated && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Tamira Admin Dashboard"
            routes={getMenuRoutes(routes)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            handleLogout={handleLogout}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {authState.isAunthenticated || sessionStorage.getItem('userToken') ?
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/body-parts" />} />
          <Route path="/technique/:treatmentId" exact element={ <TechniqueManagement/> } />
          <Route path="/url/:treatmentId" exact element={ <TreatmentLinkManagement/> } />
          <Route path="/faq/:treatmentId" exact element={ <FaqManagement/> } />
          <Route path="/testimonial/:treatmentId" exact element={ <TestimonialManagement/> } />
          <Route path="/video/:treatmentId" exact element={ <VideoManagement/> } />

          <Route path="/symptom/:bodyPartId" exact element={ <BodyPartSymptomManagement/> } />
          <Route path="/disease/:bodyPartId/:symptomId" exact element={ <DiseaseManagement/> } />
          {/* Doctor */}
          <Route path="/doctor-clinic/:doctorId" exact element={ <DoctorClinicManagement/> } />
          <Route path="/doctor-education/:doctorId" exact element={ <DoctorEducationManagement/> } />
          <Route path="/doctor-membership/:doctorId" exact element={ <DoctorMembershipManagement/> } />

          {/* Disease Treatment */}
          <Route path="/disease-treatment/:diseaseId" exact element={ <DiseaseTreatmentManagement/> } />

          {/* City Treatment */}
          <Route path="/city-treatment/:cityId" exact element={ <CityTreatmentManagement/> } />
          <Route path="/city-center/:cityId" exact element={ <CityCenterManagement/> } />

          {/* Location Before after */}
          <Route path="/location/:cityId/:treatmentId" exact element={ <LocationBeforeAfterManagement/> } />
          <Route path="/location/video/:locationId/:treatmentId" exact element={ <LocationVideoManagement/> } />
          <Route path="/location/faq/:locationId/:treatmentId" exact element={ <LocationFaqManagement/> } />
          <Route path="/location/testimonials/:locationId/:treatmentId" exact element={ <LocationTestimonialManagement/> } />

          {/* template */}
          <Route path="/template" exact element={ <TemplateManagement/> } />

          {/* Iv Therapy Benefits */}
          <Route path="/iv-therapy-benefit/:ivTherapyId" exact element={ <IvTherapyBenefitManagement/> } />
          {/* Iv Therapy Ingredient */}
          <Route path="/iv-therapy-ingredient/:ivTherapyId" exact element={ <IvTherapyIngredientManagement/> } />
          <Route path="/iv-therapy-testimonial/:treatmentId" exact element={ <IvTherapyTestimonialManagement/> } />
          <Route path="/iv-therapy-faq/:ivTherapyId" exact element={ <IvTherapyFaqManagement/> } />

        </Routes> :
        <Routes>
          {getRoutes([routes[0]])}
          <Route path="*" element={<Navigate to="/sign-in" />} />
          <Route path="/verify" exact element={<Verification/>} />
        </Routes>}
    </ThemeProvider>
  );
}
