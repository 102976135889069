import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url } from '../../config';
import {  useParams } from "react-router-dom";
// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link } from '@mui/material';
//import Breadcrumbs from "examples/Breadcrumbs";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import videoTableData from "layouts/LocationVideo/data/LocationVideoTableData";

import { useDispatch, useSelector } from 'react-redux';
// import { getSingleTreatmentAction } from '../../store/TreatmentManagement/action';
import { getAllVideosAction, saveVideoAction, editVideoAction } from '../../store/CityTreatmentFaqManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Options() {
  let { treatmentId, locationId } = useParams();
  const state = useSelector(state=> state.cityTreatmentVideo);
  console.log('gg',state)
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  //Form Data
  const [youtubeId, setYoutubeId] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [treatment, setTreatment] = useState({});
  const [videoId, setVideoId] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setYoutubeId("");
    setType("");
    setTitle("");
    setVideoId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = videoTableData({treatmentId, locationId, handleOpen, setYoutubeId, setType, setTitle, setVideoId, setShowActive });

  useEffect(() => {
    dispatch(getAllVideosAction(treatmentId, locationId));
  }, [treatmentId]);

  // useEffect(() => {
  //   //Lists
  //   if (state.treatment !== undefined) {
  //     setTreatment(state.treatment);
  //     //console.log(state.treatment);
  //   }
  // }, [state]);

  // const handleTypeChange = (event) => {
  //   setType(event.target.value);
  // };

  const uploadVideo = (event) => {
    event.preventDefault();
    if (videoId)
    {
      const formData = {
        "youtube_id" : youtubeId,
        "title" : title,
        "treatment_id" : treatmentId,
        "location_id":locationId
      }
      dispatch(editVideoAction(formData, videoId, treatmentId, locationId));
      setVideoId("");
      //dispatch(getAllVideosAction(treatmentId, locationId));
      handleClose();
    }else{
      const formData = {
        "youtube_id" : youtubeId,
        "title" : title,
        "treatment_id" : treatmentId,
        "location_id":locationId
      }
      dispatch(saveVideoAction(formData, treatmentId, locationId));
      //dispatch(getAllVideosAction(treatmentId, locationId));
      handleClose();
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/backend/treatments"
        >
          {treatment.title}
        </Link>
        <Typography color="text.primary">Video</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Video
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadVideo}>
                <FormGroup>
                  {/* <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Type</InputLabel>
                      <Select
                        labelId="type-label"
                        id="demo-simple-select"
                        label="Type"
                        value={type}
                        onChange={handleTypeChange}
                      >
                        <MenuItem value={1}>Treatment</MenuItem>
                        <MenuItem value={2}>Before After</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Youtube ID</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={youtubeId} onChange={(e) => setYoutubeId(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Videos
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Options;
