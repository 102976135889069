import { GET_ALL_ALLIED_HEALTH_SERVICE, GET_SINGLE_ALLIED_HEALTH_SERVICE, EDIT_ALLIED_HEALTH_SERVICE, SAVE_ALLIED_HEALTH_SERVICE, DELETE_ALLIED_HEALTH_SERVICE } from "../actionType";

const initialState = {
  doctors: [],
  doctor: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ALLIED_HEALTH_SERVICE:
      return {
        ...state,
        doctors: action.payload,
      };

    case GET_SINGLE_ALLIED_HEALTH_SERVICE:
      return {
        ...state,
        doctor: action.payload,
      };

    case EDIT_ALLIED_HEALTH_SERVICE:
      return {
        ...state,
      };

    case SAVE_ALLIED_HEALTH_SERVICE:
      return {
        ...state,
      };

    case DELETE_ALLIED_HEALTH_SERVICE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
