/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

import { region, accessKeyId, secretAccessKey, s3Url, S3, bucketName } from '../../../config';
import { Link } from "react-router-dom";
// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllDoctorsAction, deleteDoctorAction } from '../../../store/AlliedHealthServiceManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.alliedHealthService);
  const dispatch = useDispatch();

  const [doctors, setDoctors] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);

  const roleArray = ["","","", "","Dietitian / Nutritionist", "Physiotherapy", "Home Nurses", "Clinical Psychology"];

  useEffect(() => {
    dispatch(getAllDoctorsAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.doctors !== undefined) {
      setDoctors(state.doctors);
    }    
    // axios.get("http://api.tamiraplatform.metricserp.net/api/v1/doctor/fetch-all-banner",{
    //   headers: {
    //     "x-auth-token": sessionStorage.getItem('userToken') //the token is a variable which holds the token
    //   }
    // })
    // .then(result => {
    //   console.log(result.data.fetchBanner);
    //   setDoctors(result.data.fetchBanner);
    // })
  }, [state]);

  const handleDeleteDoctor = (index) => {
    // const config = {
    //   bucketName : bucketName,
    //   dirName : "doctor",
    //   region : region,
    //   accessKeyId : accessKeyId,
    //   secretAccessKey : secretAccessKey
    // };
    // const ReactS3Client = new S3(config);
    // ReactS3Client
    // .deleteFile(s3Url+"/doctor/"+doctors[index].upload_image)
    // .then((response) => {
    //   dispatch(deleteDoctorAction(doctors[index].user_id));   
    //   dispatch(getAllDoctorsAction());
    // })
    // .catch(err => console.error(err))
    dispatch(deleteDoctorAction(doctors[index].user_id));   
    dispatch(getAllDoctorsAction());
  }

  const handleEditDoctor = (doctor) => {
    console.log(doctor);
    props.setFirstName(doctor.first_name);
    props.setLastName(doctor.last_name);
    props.setMobile(doctor.mobile);
    props.setEmail(doctor.email_address);
    props.setLocation(doctor.location);
    props.setLocationId(doctor.location_id);
    props.setCity(doctor.city);
    props.setState(doctor.state);
    props.setAbout(doctor.about);
    props.setAbout1(doctor.about);    
    // props.setQualification(doctor.qualification);
    // props.setAccrediations(doctor.accrediations);
    props.setRole(doctor.role_id);
    props.setExperience(doctor.experience);
    props.setShowName(doctor.upload_image);
    props.setDoctorKeywords(doctor.keywords);
    props.setDoctorTreatments(doctor.treatments);
    props.setDoctorServices(doctor.services);
    props.setDoctorSpecializations(doctor.specializations);
    props.setDoctorId(doctor.user_id);
    props.setAlt(doctor.user_alt_text);
    props.setShowActive(true);
    props.handleOpen();
  };

  

  useEffect(() => {
    const roleIdGrater = doctors.filter(function(number) {
      return number.role_id > 3;
    });

    const allDoctors1 = roleIdGrater.map((doctor, index)=> (
      {
        doctorImage: <Author image={s3Url+"/doctor/"+doctor.upload_image} name={doctor.name} />,
        doctorName: doctor.first_name+" "+doctor.last_name,
        doctorType: roleArray[doctor.role_id],
        doctorMobile: (doctor.mobile),
        clinic: (<Link to={"/doctor-clinic/"+doctor.user_id+"?type=1"}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Clinic</MDButton></Link>),
        education: (<Link to={"/doctor-education/"+doctor.user_id+"?type=1"}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Education</MDButton></Link>),
        membership: (<Link to={"/doctor-membership/"+doctor.user_id+"?type=1"}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Membership</MDButton></Link>),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={()=>handleEditDoctor(doctor)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteDoctor(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));

    setAllDoctors(allDoctors1);
    //console.log('ddd',allDoctors1);
  }, [doctors]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Doctor Image", accessor: "doctorImage", width: "25%", align: "left" },
      { Header: "Name", accessor: "doctorName", width: "15%", align: "left" },
      { Header: "Doctor Type", accessor: "doctorType", width: "15%", align: "left" },
      { Header: "Mobile", accessor: "doctorMobile", align: "left" },
      { Header: "Clinic", accessor: "clinic", align: "left" },
      { Header: "Education", accessor: "education", align: "left" },
      { Header: "Membership", accessor: "membership", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: allDoctors
  };
}
