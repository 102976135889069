import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { ApiBaseUrl, accessKeyId, secretAccessKey, region, s3Url, bucketName, editorKey } from '../../config';
import {  useParams } from "react-router-dom";
// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link, Autocomplete, TextField, CircularProgress } from '@mui/material';
//import Breadcrumbs from "examples/Breadcrumbs";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import cityTreatmentTableData from "layouts/cityTreatment/data/cityTreatmentTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllTreatmentsAction } from '../../store/TreatmentManagement/action';
import { getAllCityTreatmentsAction, saveCityTreatmentAction, editCityTreatmentAction } from '../../store/CityTreatmentManagement/action';
import { getSingleLocationAction } from '../../store/LocationManagement/action';
import { Editor } from '@tinymce/tinymce-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let treatments = [];

function CityTreatment() {
  let { cityId } = useParams();
  
  const state = useSelector(state=> state.treatment);
  const locationState = useSelector(state=> state.location);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [locationTitle, setLocationTitle] = useState("");
  const [locationSlug, setLocationSlug] = useState("");
  const [showActive, setShowActive] = React.useState(false);
  //Form Data
  const [cityTreatmentId, setCityTreatmentId] = useState("");
  const [treatmentId, setTreatmentId] = useState("");
  const [slug, setSlug] = useState('');

  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const [section1Title, setSection1Title] = useState("");
  const [section1Description, setSection1Description] = useState("");
  const [section1Description1, setSection1Description1] = useState("");

  const [section2Title, setSection2Title] = useState("");
  const [section2Description, setSection2Description] = useState("");
  const [section2Description1, setSection2Description1] = useState("");

  const [section3Title, setSection3Title] = useState("");
  const [section3Description, setSection3Description] = useState("");
  const [section3Description1, setSection3Description1] = useState("");

  const [bannerText, setBannerText] = useState("");
  const [bannerAltText, setBannerAltText] = useState("");

  const [bannerImage, setBannerImage] = React.useState([]);
  const [bannerImageName, setBannerImageName] = React.useState("");
  const [showBannerImageName, setShowBannerImageName] = React.useState("");

  const [addDateTime, setAddDateTime] = useState("");
  const [updateDateTime, setUpdateDateTime] = useState("");
  
  const [treatment, setTreatment] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setCityTreatmentId("");
    setTreatmentId("");
    setTreatment([]);
    setSlug("");
    setTitle("");
    setMetaTitle("");
    setMetaDescription("");
    setSection1Title("");
    setSection1Description("");
    setSection1Description1("");
    setSection2Title("");
    setSection2Description("");
    setSection2Description1("");
    setSection3Title("");
    setSection3Description("");
    setSection3Description1("");
    setBannerText("");
    setBannerImageName("");
    setShowBannerImageName("");
    setBannerImage("");
    setTreatmentId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = cityTreatmentTableData({handleOpen, cityId, locationSlug, setCityTreatmentId, setSlug, setTitle, setMetaTitle, setMetaDescription, setSection1Title, setSection1Description, setSection1Description1, setSection2Title, setSection2Description, setSection2Description1, setSection3Title, setSection3Description, setSection3Description1, setBannerText, setBannerAltText,  setBannerImageName, setBannerImage, setShowBannerImageName, setAddDateTime, setUpdateDateTime, setTreatmentId, setTreatment, setShowActive });

  const fileInput = useRef();

  useEffect(() => {
    dispatch(getAllTreatmentsAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.treatment !== undefined) {
      treatments = state.treatments;
    }   
  }, [state]);

  useEffect(() => {
    dispatch(getSingleLocationAction(cityId));       
  }, [cityId]);

  useEffect(() => {
    if (locationState.location !== undefined) {
      //console.log(locationState.location);
      if(locationState.location.length > 0)
      {
        setLocationTitle(locationState.location[0].title);
        setLocationSlug(locationState.location[0].slug);
      }
    }
  }, [locationState]); 

  const getRandomimageName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
  }

  const handleBannerImageInput = (e) => {
    setBannerImage(e.target.files[0]);
    setBannerImageName(getRandomimageName() + e.target.files[0].name);
  }

  const saveDraft = (event) => {

  }

  const uploadCityTreatment = (status) => {
    //event.preventDefault();
    setLoading(true);
    const config = {
      bucketName: bucketName,
      dirName: "cityTreatment",
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    };
    const ReactS3Client = new S3(config);
    let bannerImageName1 = "";

    if (bannerImageName != "") {
      if (showBannerImageName == "" || showBannerImageName == null) {
        ReactS3Client.uploadFile(bannerImage, bannerImageName)
          .then(data1 => {
            bannerImageName1 = bannerImageName;
            //setMainImageName(imageName);
          })
          bannerImageName1 = bannerImageName;
      } else {
        // ReactS3Client
        // .deleteFile(showBannerImageName)
        // .then((response) => {
        //   ReactS3Client.uploadFile(bannerImage, bannerImageName)
        //     .then(data1 => {
        //       //setMainImageName(imageName);
        //     })
        // })
        // .catch(err => console.error(err));
        ReactS3Client.uploadFile(bannerImage, bannerImageName)
        .then(data1 => {
          //setMainImageName(imageName);
        })
        .catch(err => console.error(err));
        bannerImageName1 = bannerImageName;
      }
    } else {
      bannerImageName1 = showBannerImageName;
    }
    if (cityTreatmentId)
    {
      let is_draft = 0;
      if(status == 0)
      {
        is_draft = 1;
      }
      const formData = {
        "city_id" : cityId,
        "treatment_id" : treatmentId,
        "title": title,
        "slug": slug,
        "meta_title": metaTitle,
        "meta_description": metaDescription,
        "section1_title" : section1Title,
        "section1_description" : section1Description,
        "section2_title" : section2Title,
        "section2_description" : section2Description,
        "section3_title" : section3Title,
        "section3_description" : section3Description,
        "banner_text" : bannerText,
        "banner_alt_text" : bannerAltText,
        "banner_image" : bannerImageName1,
        "is_draft" : is_draft
      }
      dispatch(editCityTreatmentAction(cityId, formData, cityTreatmentId));
      setCityTreatmentId("");
      setTimeout(() => {
        dispatch(getAllCityTreatmentsAction(cityId)); 
        setLoading(false);
      }, 2000);      
      handleClose();
    }else{
      const formData = {
        "city_id" : cityId,
        "treatment_id" : treatmentId,
        "slug": slug,
        "meta_title": metaTitle,
        "meta_description": metaDescription,
        "section1_title" : section1Title,
        "section1_description" : section1Description,
        "section2_title" : section2Title,
        "section2_description" : section2Description,
        "section3_title" : section3Title,
        "section3_description" : section3Description,
        "banner_text" : bannerText,
        "banner_alt_text" : bannerAltText,
        "banner_image" : bannerImageName1,
        "publish_status" : status
      }
      dispatch(saveCityTreatmentAction(cityId, formData));
      setTimeout(() => {
        dispatch(getAllCityTreatmentsAction(cityId)); 
        setLoading(false);
      }, 2000);
      handleClose();
    }
  }

  return (
    <DashboardLayout>
      {loading && (
        <div className='c-loader'>
          <CircularProgress />
        </div>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
        >
          {locationTitle}
        </Link>
        <Typography color="text.primary">Treatment</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Treatment
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id">
                <FormGroup>
                  <Grid item xs={12}>
                    <Autocomplete
                        id="size-small-standard-multi"
                        size="small"
                        options={treatments}
                        onChange={(event, value) => {setTreatmentId(value.treatment_id), setSlug(value.slug)}}
                        getOptionLabel={(option) => option.title}
                        defaultValue={treatment}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"treatment"+option.treatment_id}>
                                {option.title}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Treatment"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Permalink</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={slug} onChange={(e) => setSlug(e.target.value)} />
                    </FormControl>
                  </Grid>

                  {/* Sections */}

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Description</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Section 1 Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={section1Title} onChange={(e) => setSection1Title(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Section 1 Description</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setSection1Description(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={section1Description1}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'                          
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Section 2 Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={section2Title} onChange={(e) => setSection2Title(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Section 2 Description</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setSection2Description(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={section2Description1}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'                          
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Section 3 Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={section3Title} onChange={(e) => setSection3Title(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Section 3 Description</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setSection3Description(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={section3Description1}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'                          
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Banner Text</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={bannerText} onChange={(e) => setBannerText(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Banner Alt Text</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={bannerAltText} onChange={(e) => setBannerAltText(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Banner Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleBannerImageInput} />
                    </FormControl>
                  </Grid>
                  {showBannerImageName != "" && (
                    <Grid item xs={12} className={showActive ? "show" : "hide"}>
                      <FormControl>
                        <InputLabel htmlFor="my-input">Existing Banner Image</InputLabel>
                        <img src={s3Url + "/cityTreatment/" + showBannerImageName} width="100" height="100"/>
                      </FormControl>
                    </Grid>
                  )}
                </FormGroup>
                <MDButton color="success" onClick={() => uploadCityTreatment(1)}>
                  Submit
                </MDButton>
                <MDButton color="info" style={{marginLeft:10}} onClick={() => uploadCityTreatment(0)}>
                  Save As Draft
                </MDButton>                
                <MDButton color="error" className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:12}}>
                  <p className='m-0' style={{fontSize:13}}>Last edited on { addDateTime }</p><p className='m-0' style={{fontSize:13}}>Published On: { updateDateTime }</p></div>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">Treatments</MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CityTreatment;
