/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Signin from "layouts/authentication/sign-in";
import Verification from "layouts/authentication/otp-verify";
import Dashboard from "layouts/dashboard";
import BannerManagement from "layouts/banner";
import BodyPartManagement from "layouts/bodyPart";
import ConditionManagement from "layouts/condition";
import ServiceManagement from "layouts/service";
import SpecializationManagement from "layouts/specialization";
import DoctorManagement from "layouts/doctor";
import ClinicManagement from "layouts/clinic";
import KeywordManagement from "layouts/keyword";
import MajorCategoryManagement from "layouts/majorCategory";
import TreatmentManagement from "layouts/treatment";
import DiseaseManagement from "layouts/disease";
import MedicineManagement from "layouts/medicine";
// import CauseManagement from "layouts/cause";
// import OptionManagement from "layouts/option";
// import TechniqueManagement from "layouts/technique";
// import AreaManagement from "layouts/area";
import LocationManagement from "layouts/location";
import SymptomTreatmentManagement from "layouts/symptom";
import BeforeAfterManagement from "layouts/beforeAfter";
import BlogManagement from "layouts/blog"
import BlogCategoryManagement from "layouts/postCategory";
import NewsletterManagement from "layouts/newsletter";
import TemplateManagement from "layouts/template";
import AlliedHealthServiceManagement from "layouts/allied-health-service";
import IvTherapyManagement from "layouts/ivTherapy";
import PageManagement from "layouts/Page";


// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "SignIn",
    key: "sign-in",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/sign-in",
    component: <Signin />,
  },
  {
    type: "collapse",
    name: "Verification",
    key: "verify",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/verify",
    component: <Verification/>,
  },
  {
    type: "collapse",
    name: "Banner",
    key: "banners",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/banners",
    component: <BannerManagement />,
  },
  {
    type: "collapse",
    name: "Body Part",
    key: "body-parts",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/body-parts",
    component: <BodyPartManagement />,
  },
  {
    type: "collapse",
    name: "Symptoms",
    key: "symptom",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/symptom",
    component: <SymptomTreatmentManagement />,
  },
  {
    type: "collapse",
    name: "Disease",
    key: "diseases",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/diseases",
    component: <DiseaseManagement />,
  },
  {
    type: "collapse",
    name: "Treatment",
    key: "treatments",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/treatments",
    component: <TreatmentManagement />,
  }, 
  {
    type: "collapse",
    name: "Blog",
    key: "blog",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/blog",
    component: <BlogManagement />,
  },  
  {
    type: "collapse",
    name: "Post Category",
    key: "post-category",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/post-category",
    component: <BlogCategoryManagement />,
  },
  {
    type: "collapse",
    name: "Doctor",
    key: "doctors",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/doctors",
    component: <DoctorManagement />,
  },
  {
    type: "collapse",
    name: "Clinic",
    key: "clinics",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/clinics",
    component: <ClinicManagement />,
  },
  // {
  //   type: "collapse",
  //   name: "Area",
  //   key: "areaManagement",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/areas",
  //   component: <AreaManagement />,
  // },
  {
    type: "collapse",
    name: "Medicine",
    key: "medicines",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/medicines",
    component: <MedicineManagement />,
  },
  {
    type: "collapse",
    name: "Service",
    key: "services",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/services",
    component: <ServiceManagement />,
  },
  {
    type: "collapse",
    name: "Specialization",
    key: "specializations",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/specializations",
    component: <SpecializationManagement />,
  },
  {
    type: "collapse",
    name: "Keyword",
    key: "keywords",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/keywords",
    component: <KeywordManagement />,
  },
  {
    type: "collapse",
    name: "Major Category",
    key: "major-category",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/major-category",
    component: <MajorCategoryManagement />,
  },   
  // {
  //   type: "collapse",
  //   name: "Cause",
  //   key: "causeManagement",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/causes",
  //   component: <CauseManagement />,
  // },
  // {
  //   type: "collapse",
  //   name: "Option",
  //   key: "optionManagement",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/options",
  //   component: <OptionManagement />,
  // }, 
  {
    type: "collapse",
    name: "Location",
    key: "locations",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/locations",
    component: <LocationManagement />,
  },  
  {
    type: "collapse",
    name: "BeforeAfter",
    key: "before-after",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/before-after",
    component: <BeforeAfterManagement />,
  },
  {
    type: "collapse",
    name: "Newsletter",
    key: "newsletter",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/newsletter",
    component: <NewsletterManagement />,
  },
  {
    type: "collapse",
    name: "Template",
    key: "template",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/template",
    component: <TemplateManagement />,
  },
  {
    type: "collapse",
    name: "Ivtherapy",
    key: "iv-therapy",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/iv-therapy",
    component: <IvTherapyManagement />,
  },
  {
    type: "collapse",
    name: "Allied Health Service",
    key: "allied-health-service",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/allied-health-service",
    component: <AlliedHealthServiceManagement />,
  },
  {
    type: "collapse",
    name: "Page Management",
    key: "page-management",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/page-management",
    component: <PageManagement />,
  }
];

export default routes;