import { GET_ALL_BEFORE_AFTERS, EDIT_BEFORE_AFTER, SAVE_BEFORE_AFTER, DELETE_BEFORE_AFTER, DELETE_BEFORE_AFTERS } from "../actionType";
import { axiosInstance, getAllBeforeAfters, addBeforeAfter, editBeforeAfter, deleteBeforeAfter, deleteBeforeAfters } from "../../services/Apis";

export const getAllBeforeAftersAction = () => (dispatch) => {
  axiosInstance
    .get(getAllBeforeAfters, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTechnique);
      dispatch({
        type: GET_ALL_BEFORE_AFTERS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editBeforeAfterAction = (formData, beforeAfterId) => (dispatch) => {
  axiosInstance
  .put(editBeforeAfter+"/"+beforeAfterId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_BEFORE_AFTER,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveBeforeAfterAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addBeforeAfter, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_BEFORE_AFTER,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteBeforeAfterAction = (beforeAfterId) => (dispatch) => {
  axiosInstance
  .delete(deleteBeforeAfter+"/"+beforeAfterId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_BEFORE_AFTER,
      payload: response.data,
    });
  }).then(()=> dispatch(getAllBeforeAftersAction()))
  .catch((error) => console.log(error));
};

export const deleteBeforeAftersAction = (data) => (dispatch) => {
  axiosInstance
  .post(deleteBeforeAfters, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_BEFORE_AFTERS,
      payload: response.data,
    });
  })
  .then(window.location.reload())
  .catch((error) => console.log(error));
};
