/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';
import S3 from "react-aws-s3";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import {useDispatch, useSelector} from 'react-redux';
import { getAllBeforeAftersAction, deleteBeforeAfterAction } from '../../../store/CityTreatmentBeforeAfterManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.cityTreatmentBeforeAfter);
  const dispatch = useDispatch();

  const [beforeafters, setBeforeAfters] = useState([]);
  const [allBeforeAfters, setAllBeforeAfters] = useState([]);

  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const config = {
    bucketName : bucketName,
    dirName : "city-treatment-before-after",
    region : region,
    accessKeyId : accessKeyId,
    secretAccessKey : secretAccessKey
  };

  useEffect(() => {
    // console.log('ddg',props)
    dispatch(getAllBeforeAftersAction(props.cityId, props.treatmentId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.images !== undefined) {
      setBeforeAfters(state.images);
    }
  }, [state]);

  const handleDeleteBeforeAfter = (index) => {
    const before_image = beforeafters[index].before_image;
    const after_image = beforeafters[index].after_image;
    const ReactS3Client = new S3(config);

    dispatch(deleteBeforeAfterAction(props.cityId, props.treatmentId, beforeafters[index].before_after_id)); 
    setTimeout(() => {
      dispatch(getAllBeforeAftersAction(props.cityId, props.treatmentId)); 
    }, 2000);  

    // ReactS3Client
    // .deleteFile(before_image)
    // .then((response) => {
    //   ReactS3Client
    //   .deleteFile(after_image)
    //   .then((response) => {
    //     dispatch(deleteBeforeAfterAction(props.cityId, props.treatmentId, beforeafters[index].id));   
    //     //dispatch(getAllBeforeAftersAction(props.cityId, props.treatmentId));
    //   })
    // })
    // .catch(err => console.error(err))
        
  }

  const fileInput = useRef();

  const handleEditBeforeAfter = (beforeafter) => {
    //console.log(cause);
    props.setshowBeforeImageName(beforeafter.before_image);
    //props.setshowAfterImageName(beforeafter.after_image);
    //props.setTreatmentId(beforeafter.treatment_id);
    //props.setDoctorId(beforeafter.doctor_id);
    props.setAltText(beforeafter.alt_tag);
    props.setBeforeAfterId(beforeafter.before_after_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const allBeforeAfters1 = beforeafters.map((beforeafter, index) => (
      {
        beforeImage: <Author image={s3Url+"/city-treatment-before-after/"+beforeafter.before_image} name={beforeafter.alt_tag} />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditBeforeAfter(beforeafter)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteBeforeAfter(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    //console.log(allBeforeAfters1);
    setAllBeforeAfters(allBeforeAfters1);
  }, [beforeafters]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Before After Image", accessor: "beforeImage", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allBeforeAfters
  };
}