import { GET_ALL_ALLIED_HEALTH_SERVICE, GET_SINGLE_ALLIED_HEALTH_SERVICE, EDIT_ALLIED_HEALTH_SERVICE, SAVE_ALLIED_HEALTH_SERVICE, DELETE_ALLIED_HEALTH_SERVICE } from "../actionType";
import { axiosInstance, getAllAlliedServices, addAlliedServices, deleteAlliedServices, editAlliedServices } from "../../services/Apis";

export const getAllDoctorsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllAlliedServices, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchDoctor[0]);
      dispatch({
        type: GET_ALL_ALLIED_HEALTH_SERVICE,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getSingleDoctorAlliedAction = (doctorId) => (dispatch) => {
  axiosInstance
    .get(getAllAlliedServices+"/"+doctorId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      // console.log(response.data.data[0]);
      dispatch({
        type: GET_SINGLE_ALLIED_HEALTH_SERVICE,
        payload: response.data.data[0],
      });
    })
    .catch((error) => console.log(error));
};

export const editDoctorAction = (doctorId, data) => (dispatch) => {
  axiosInstance
  .put(editAlliedServices+"/"+doctorId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_ALLIED_HEALTH_SERVICE,
      payload: response.data,
    });
  })
  .then(dispatch(getAllDoctorsAction(dispatch)))
  .catch((error) => console.log(error));
};

export const saveDoctorAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addAlliedServices, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_ALLIED_HEALTH_SERVICE,
      payload: response.data,
    });
  })
  .then(dispatch(getAllDoctorsAction(dispatch)))
  .catch((error) => console.log(error));
};

export const deleteDoctorAction = (doctorId) => (dispatch) => {
  console.log('doctorId', doctorId);
  axiosInstance
  .delete(deleteAlliedServices+"/"+doctorId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_ALLIED_HEALTH_SERVICE,
      payload: response.data,
    });
  })
  .then(dispatch(getAllDoctorsAction(dispatch)))
  .catch((error) => console.log(error));
};
